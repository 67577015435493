<template>
  <div class="privacy-agreement">
      <h2>隐私声明</h2>
      <div v-html="detail" class="details"></div>
      <div class="history-version" @click="$router.push('/privacyHistory')">查看隐私政策历史版本>></div>
  </div>
</template>

<script>
import { getQueryPrivacyStatement } from '@/api/api'
export default {
  name: "PrivacyAgreement",
  components: {
  },
  data() {
    return {
      detail: '', //内容
      keyword: '隐私政策', //查询关键词
    };
  },
  created() {
    this.getDetails();
  },
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  },
  methods: {
    getDetails() {
      getQueryPrivacyStatement({pageTitle: '隐私声明'}).then((res) => {
        if (res.result === 0) {
          this.detail = res.data.articleBody
          if (!this.detail) {
            this.$router.replace('/privacyHistory')
          }
        } else {
        }
      }).catch((err) => {
      })
    },
  }
};
</script>

<style lang="less" scoped>
.privacy-agreement {
  
  position: relative;
  h2 {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .details{
    padding: 10px 22px;
    line-height: 25px;
    font-size: 13px;
  }
  :deep( img){
    max-width: 100%;
  }
  :deep( a){
    word-wrap: break-word;
  }
  :deep( video){
    max-width: 100%;
  }
  :deep( p){
    word-break: break-all;
  }
  :deep( p,div){
    margin-top: 10px;
  }
  .history-version {
    position: fixed;
    bottom: 0;
    height: 48px;
    width: 100%;
    text-align: right;
    color: #2D8CF0;
    background: #FFF;
    line-height: 48px;
    font-size: 12px;
    padding: 0 20px;
    cursor: pointer;
  }
}

</style>
